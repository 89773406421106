
import { Component, Vue } from 'vue-property-decorator';
import { Teachers } from '@/store/modules';

@Component
export default class Payments extends Vue {
  loading = true
  saving = false
  error = false
  errorSaving = false
  saved = false;

  payoutMethod = ''
  accountName = ''
  accountEmailId = ''

  payoutMethods = [
    { text: 'None', value: '' },
    { text: 'PingPongX', value: 'PingPongX' },
    { text: 'Payoneer', value: 'Payoneer' },
    { text: 'Veem', value: 'Veem' }
  ]

  async mounted() {
    this.loading = true
    try {
      const payoutInfo = await Teachers.getPayoutInfo();
      this.payoutMethod = payoutInfo.payoutMethod || '';
      this.accountName = payoutInfo.accountName || '';
      this.accountEmailId = payoutInfo.accountEmailId || '';
    } catch (err) {
      console.warn(err.message);
      this.error = true
    }
    this.loading = false
  }

  async save() {
    this.saving = true
    this.errorSaving = false;
    try {
      const toSave: any = {};

      if (!this.payoutMethod) {
        toSave.payoutInfo = {};
      } else {
        toSave.payoutInfo = {
          payoutMethod: this.payoutMethod,
          accountName: this.accountName,
          accountEmailId: this.accountEmailId
        };
      }

      await Teachers.updateTeacher(toSave);
      this.saved = true;
    } catch (err) {
      console.warn(err.message);
      this.errorSaving = true;
    }
    this.saving = false;
  }

  get enableSaveButton() {
    if (!this.payoutMethod) {
      return true;
    }

    if (this.accountName && this.accountEmailId) {
      return true;
    }

    return false;
  }
}
